import React from "react";
import Layout from "../components/Layout";
import Seo from "../components/seo";
import Gallery from "../components/Gallery";

const FashionPage = () => (
  <Layout descriptionText={"Fashion"}>
    <Seo title="fashion" />

    <Gallery categoryName="fashion" />
  </Layout>
);

export default FashionPage;
